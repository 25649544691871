'use client';

import { Fragment } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useIntersectionObserver } from 'usehooks-ts';

import {
  BlogPost,
  BlogPostContent,
  BlogPostContentPlaceholder,
  BlogPostHeader,
  BlogPostHeaderPlaceholder,
  BlogPostPlaceholder,
  Button,
  CaretLeftIcon,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  LocaleLink,
  ScrollArea,
  Skeleton,
  TypographyH5,
} from '@/components';
import { getPostsByCategory } from '@/services';
import type { MagazinePost } from '@/types';
import { PATHNAME, randomId, TAG } from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    MAG: {
      BASE_URL: MAG_BASE_URL,
      LAST_ARTICLE: { BASE_URL: MAG_LAST_ARTICLE_BASE_URL },
    },
  },
  QUERY_PARAM: {
    DEFAULT: { POST_SIZE: DEFAULT_POST_SIZE },
  },
} = PATHNAME;
const {
  SERVICE: {
    MAGAZINE: {
      POST: { GLOBAL: GLOBAL_MAGAZINE_POST_TAG },
    },
  },
} = TAG;

const HomeMagazineHeader = () => (
  <div className='container flex flex-wrap items-center justify-between'>
    <TypographyH5 className='font-bold'>آخرین مقالات</TypographyH5>
    <Button
      asChild
      variant='link'
      className='p-0 font-bold text-inherit max-lg:text-xs'
    >
      <LocaleLink
        target='_blank'
        href={`${MAG_BASE_URL}${MAG_LAST_ARTICLE_BASE_URL}${DEFAULT_SLUG_SEPARATOR}`}
        className='gap-2'
      >
        موارد بیشتر
        <CaretLeftIcon className='aspect-square size-6 rounded-full bg-muted p-1 text-foreground' />
      </LocaleLink>
    </Button>
  </div>
);

type HomeMagazineContentProps = Record<'posts', MagazinePost[]>;

const HomeMagazineContent = ({ posts }: HomeMagazineContentProps) => (
  <Fragment>
    <div className='container'>
      <Carousel className='place-items-center gap-4 max-lg:hidden'>
        <CarouselContent>
          {posts.map(post => {
            const { id } = post;

            return (
              <CarouselItem key={id} className='basis-2/5'>
                <BlogPost {...post} className='h-44'>
                  <BlogPostHeader className='flex-1' />
                  <BlogPostContent className='flex-1' />
                </BlogPost>
              </CarouselItem>
            );
          })}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
    <ScrollArea type='scroll' orientation='horizontal' className='lg:hidden'>
      <div className='container flex gap-4'>
        {posts.map(post => {
          const { id } = post;

          return (
            <BlogPost
              key={id}
              {...post}
              className='min-h-44 w-64 shrink-0 flex-col'
            >
              <BlogPostHeader className='aspect-video flex-1' />
              <BlogPostContent className='flex-1' />
            </BlogPost>
          );
        })}
      </div>
    </ScrollArea>
  </Fragment>
);

const HomeMagazinePlaceholders = () => (
  <div className='container space-y-8'>
    <div className='flex items-center justify-between'>
      <Skeleton className='h-10 w-24' />
      <Skeleton className='h-10 w-24' />
    </div>
    <div className='flex flex-nowrap items-center justify-between gap-4 overflow-hidden'>
      {Array.from<number, string>({ length: 3 }, (_v, _k) => randomId()).map(
        value => (
          <BlogPostPlaceholder
            key={value}
            className='min-h-44 shrink-0 max-lg:w-64 max-lg:flex-col lg:basis-2/5'
          >
            <BlogPostHeaderPlaceholder className='flex-1 max-lg:aspect-video' />
            <BlogPostContentPlaceholder className='flex-1' />
          </BlogPostPlaceholder>
        ),
      )}
    </div>
  </div>
);

const HomeMagazine = () => {
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
  });

  const { data: posts = [], isFetched } = useQuery({
    queryKey: [GLOBAL_MAGAZINE_POST_TAG],
    queryFn: () => getPostsByCategory({ size: DEFAULT_POST_SIZE }),
    enabled: isIntersecting,
    select: ({ posts }) => posts,
  });

  return (
    <div ref={ref} className='my-8 lg:my-16'>
      {isFetched ? (
        <div className='space-y-8'>
          <HomeMagazineHeader />
          <HomeMagazineContent posts={posts} />
        </div>
      ) : (
        <HomeMagazinePlaceholders />
      )}
    </div>
  );
};

export { HomeMagazine };
