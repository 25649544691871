'use client';

import { useQuery } from '@tanstack/react-query';
import { useIntersectionObserver } from 'usehooks-ts';

import {
  FingerprintWallpaper,
  ProductTabs,
  ProductTabsContent,
  ProductTabsContentPlaceholder,
  ProductTabsHeader,
  ProductTabsHeaderPlaceholder,
  ProductTabsHeaderTriggers,
  ProductTabsHeaderTriggersPlaceholder,
  ProductTabsPlaceholder,
} from '@/components';
import {
  getCategories,
  getPageCategoryProducts,
  searchProductsByCategories,
} from '@/services';
import { PATHNAME, TAG } from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    CATEGORY: { BASE_URL: CATEGORY_PAGE_BASE_URL },
  },
  QUERY_PARAM: {
    DEFAULT: { SORT_TYPE: DEFAULT_SORT_TYPE },
    SYMBOL: {
      QUESTION_MARK: QUESTION_MARK_SYMBOL,
      ASSIGNMENT: ASSIGNMENT_SYMBOL,
    },
    SORT_TYPE: { BASE_URL: SORT_TYPE_BASE_URL },
  },
} = PATHNAME;
const {
  SERVICE: {
    CATEGORY: { GLOBAL: CATEGORY_GLOBAL_TAG },
    PRODUCT: {
      NEW_GLOBAL: { BASE: NEW_PRODUCTS_GLOBAL_TAG },
    },
    PAGE_BUILDER: {
      PAGE_CATEGORY_PRODUCTS: {
        FIRST: FIRST_CATEGORY_PRODUCTS,
        SECOND: SECOND_CATEGORY_PRODUCTS,
        THIRD: THIRD_CATEGORY_PRODUCTS,
      },
    },
  },
} = TAG;

const HomeFirstProduct = () => {
  const { data, isFetched } = useQuery({
    queryKey: [FIRST_CATEGORY_PRODUCTS],
    queryFn: () =>
      getPageCategoryProducts({
        type: ['LandingPage_Pos3'],
      }),
  });

  const { Landing_Pos3_CategoryProduct: categoryProduct } =
    data?.pageSettings ?? {};

  const { mainCategory, productsByCategories = [] } = categoryProduct ?? {};

  const { title = '', slug = '' } = mainCategory ?? {};

  const info = {
    title,
    href: `${CATEGORY_PAGE_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
  };

  const tabTriggers = productsByCategories.map(({ category }) => ({
    id: category?.id || '',
    title: category?.title || '',
  }));

  const tabPanes = productsByCategories.map(({ products, category }) => ({
    id: category?.id || '',
    products,
  }));

  const tabsData = {
    info,
    tabTriggers,
    tabPanes,
  };

  return (
    <div className='my-8 lg:my-16'>
      {isFetched && categoryProduct ? (
        <ProductTabs {...tabsData}>
          <ProductTabsHeader>
            <ProductTabsHeaderTriggers />
          </ProductTabsHeader>
          <ProductTabsContent />
        </ProductTabs>
      ) : (
        <ProductTabsPlaceholder>
          <ProductTabsHeaderPlaceholder>
            <ProductTabsHeaderTriggersPlaceholder />
          </ProductTabsHeaderPlaceholder>
          <ProductTabsContentPlaceholder />
        </ProductTabsPlaceholder>
      )}
    </div>
  );
};

const HomeSecondProduct = () => {
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
  });

  const { data, isFetched } = useQuery({
    queryKey: [SECOND_CATEGORY_PRODUCTS],
    queryFn: () =>
      getPageCategoryProducts({
        type: ['LandingPage_Pos5'],
      }),
    enabled: isIntersecting,
  });

  const { Landing_Pos5_CategoryProduct: categoryProducts } =
    data?.pageSettings ?? {};

  const { mainCategory, productsByCategories = [] } = categoryProducts ?? {};

  const { title = '', slug = '' } = mainCategory ?? {};

  const info = {
    title,
    href: `${CATEGORY_PAGE_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
  };

  const tabTriggers = productsByCategories?.map(({ category }) => ({
    id: category?.id || '',
    title: category?.title || '',
  }));

  const tabPanes = productsByCategories?.map(({ products, category }) => ({
    id: category?.id || '',
    products,
  }));

  const tabsData = {
    info,
    tabTriggers,
    tabPanes,
  };

  return (
    <div ref={ref} className='my-8 lg:my-16'>
      {isFetched && categoryProducts ? (
        <FingerprintWallpaper variant='foreground'>
          <ProductTabs {...tabsData} variant='transparent'>
            <ProductTabsHeader>
              <ProductTabsHeaderTriggers className='text-muted/60 data-[state=active]:*:text-muted' />
            </ProductTabsHeader>
            <ProductTabsContent />
          </ProductTabs>
        </FingerprintWallpaper>
      ) : (
        <FingerprintWallpaper variant='foreground'>
          <ProductTabsPlaceholder>
            <ProductTabsHeaderPlaceholder>
              <ProductTabsHeaderTriggersPlaceholder />
            </ProductTabsHeaderPlaceholder>
            <ProductTabsContentPlaceholder />
          </ProductTabsPlaceholder>
        </FingerprintWallpaper>
      )}
    </div>
  );
};

const HomeThirdProduct = () => {
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
  });

  const { data, isFetched } = useQuery({
    queryKey: [THIRD_CATEGORY_PRODUCTS],
    queryFn: () =>
      getPageCategoryProducts({
        type: ['LandingPage_Pos7'],
      }),
    enabled: isIntersecting,
  });

  const { Landing_Pos7_CategoryProduct: categoryProducts } =
    data?.pageSettings ?? {};

  const { mainCategory, productsByCategories = [] } = categoryProducts ?? {};

  const { title = '', slug = '' } = mainCategory ?? {};

  const info = {
    title,
    href: `${CATEGORY_PAGE_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
  };

  const tabTriggers = productsByCategories.map(({ category }) => ({
    id: category?.id || '',
    title: category?.title || '',
  }));

  const tabPanes = productsByCategories.map(({ products, category }) => ({
    id: category?.id || '',
    products,
  }));

  const tabsData = {
    info,
    tabTriggers,
    tabPanes,
  };

  return (
    <div ref={ref} className='my-8 lg:my-16'>
      {isFetched && categoryProducts ? (
        <ProductTabs {...tabsData}>
          <ProductTabsHeader>
            <ProductTabsHeaderTriggers />
          </ProductTabsHeader>
          <ProductTabsContent />
        </ProductTabs>
      ) : (
        <ProductTabsPlaceholder>
          <ProductTabsHeaderPlaceholder>
            <ProductTabsHeaderTriggersPlaceholder />
          </ProductTabsHeaderPlaceholder>
          <ProductTabsContentPlaceholder />
        </ProductTabsPlaceholder>
      )}
    </div>
  );
};

const HomeNewProducts = () => {
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
  });

  const { data: categoriesData, isFetched: areCategoriesFetched } = useQuery({
    queryKey: [CATEGORY_GLOBAL_TAG],
    queryFn: getCategories,
    enabled: isIntersecting,
  });

  const categories = categoriesData?.categories ?? [];

  const { data: newProductsData, isFetched: areNewProductsFetched } = useQuery({
    queryKey: [NEW_PRODUCTS_GLOBAL_TAG, categories],
    queryFn: () =>
      searchProductsByCategories({
        categoriesIds: categories.map(({ id }) => id) || [],
        sortType: 'Newest',
      }),
    enabled: isIntersecting && areCategoriesFetched,
  });

  const newProducts = newProductsData?.productsByCategories ?? [];

  const info = {
    title: 'جدیدترین‌ها',
    href: `${CATEGORY_PAGE_BASE_URL}${QUESTION_MARK_SYMBOL}${SORT_TYPE_BASE_URL}${ASSIGNMENT_SYMBOL}${DEFAULT_SORT_TYPE}`,
  };

  const tabTriggers = categories.map(({ id, title, iconId: icon }) => ({
    id,
    title,
    icon,
  }));

  const tabPanes = newProducts.map(({ products, category }) => ({
    id: category?.id || '',
    products,
  }));

  const tabsData = {
    info,
    tabTriggers,
    tabPanes,
  };

  return (
    <div ref={ref} className='my-8 lg:my-16'>
      {areNewProductsFetched && newProducts && categoriesData ? (
        <ProductTabs {...tabsData}>
          <ProductTabsHeader>
            <ProductTabsHeaderTriggers className='items-baseline [&_p]:line-clamp-2 [&_p]:max-w-28' />
          </ProductTabsHeader>
          <ProductTabsContent type='grid' />
        </ProductTabs>
      ) : (
        <ProductTabsPlaceholder>
          <ProductTabsHeaderPlaceholder>
            <ProductTabsHeaderTriggersPlaceholder />
          </ProductTabsHeaderPlaceholder>
          <ProductTabsContentPlaceholder type='grid' />
        </ProductTabsPlaceholder>
      )}
    </div>
  );
};

export {
  HomeFirstProduct,
  HomeNewProducts,
  HomeSecondProduct,
  HomeThirdProduct,
};
