'use client';

import { useQuery } from '@tanstack/react-query';
import { useIntersectionObserver } from 'usehooks-ts';

import {
  BrandCard,
  BrandCardHeader,
  FingerprintWallpaper,
  KashilandIconLogo,
  Skeleton,
  TypographyH4,
} from '@/components';
import { getBestBrands } from '@/services';
import { randomId, TAG } from '@/utils';

const {
  SERVICE: {
    BRAND: {
      BEST: { GLOBAL: GLOBAL_BEST_BRAND },
    },
  },
} = TAG;

const HomeBrands = () => {
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
  });

  const { data: brands, isFetched } = useQuery({
    queryKey: [GLOBAL_BEST_BRAND],
    queryFn: getBestBrands,
    enabled: isIntersecting,
    select: data => data.brands,
  });

  const content = brands?.map(brand => {
    const { id } = brand;

    return (
      <div key={id} className='p-px'>
        <div className='bg-background'>
          <BrandCard
            className='size-full rounded-none border-none shadow-none'
            {...brand}
          >
            <BrandCardHeader className='h-40' />
          </BrandCard>
        </div>
      </div>
    );
  });

  content?.splice(
    1,
    0,
    <div className='p-px'>
      <div className='flex h-full items-center justify-center bg-background/30 '>
        <TypographyH4 className='text-balance px-4 lg:text-3xl'>
          محبوب‌ترین برنــــــــــــــدها
        </TypographyH4>
      </div>
    </div>,
  );
  content?.splice(
    12,
    0,
    <div className='p-px max-lg:hidden'>
      <div className='flex h-full items-center justify-center bg-background/30 '>
        <TypographyH4 className='text-balance px-4 lg:text-3xl'>
          در فروشگاه <span className='text-primary'>کاشی‌</span>لنــــد
        </TypographyH4>
      </div>
    </div>,
  );

  const placeholders = Array.from<number, string>({ length: 15 }, (_v, _k) =>
    randomId(),
  ).map(value => (
    <div key={value} className='h-48 p-px max-lg:last:hidden'>
      <Skeleton className='size-full rounded-none' />
    </div>
  ));

  return (
    <div ref={ref} className='my-8 space-y-5 lg:my-16'>
      <FingerprintWallpaper
        variant='border'
        className='py-8 text-center [&>div]:container'
      >
        {isFetched ? (
          <div className='relative overflow-hidden'>
            <div className='absolute inset-0 grid-cols-5 grid-rows-3 lg:grid'>
              <figure className='lg:col-span-2 lg:col-start-2 lg:row-span-3'>
                <KashilandIconLogo className='size-full' />
              </figure>
            </div>
            <div className='relative flex flex-wrap items-stretch overflow-hidden rounded-lg lg:grid-cols-5 [&>div]:basis-1/2 lg:[&>div]:basis-1/5'>
              {content}
            </div>
          </div>
        ) : (
          <div className='relative overflow-hidden'>
            <div className='relative flex flex-wrap items-center overflow-hidden rounded-lg lg:grid-cols-5 [&>div]:basis-1/2 lg:[&>div]:basis-1/5'>
              {placeholders}
            </div>
          </div>
        )}
      </FingerprintWallpaper>
    </div>
  );
};

export { HomeBrands };
