'use client';

import { useQuery } from '@tanstack/react-query';
import AutoPlay from 'embla-carousel-autoplay';
import { useIntersectionObserver } from 'usehooks-ts';

import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  PageBanner,
  PageBannerPlaceholder,
} from '@/components';
import { getPageBanners } from '@/services';
import type { PageSlider } from '@/types';
import { randomId, TAG } from '@/utils';

const {
  SERVICE: {
    PAGE_BUILDER: {
      PAGE_BANNERS: {
        FIRST: FIRST_PAGE_BANNERS,
        SECOND: SECOND_PAGE_BANNERS,
        THIRD: THIRD_PAGE_BANNERS,
        FOURTH: FOURTH_PAGE_BANNERS,
      },
    },
  },
} = TAG;

type HomeSliderProps = Record<'slider', PageSlider>;

const HomeSlider = ({ slider }: HomeSliderProps) => (
  <Carousel
    plugins={[
      AutoPlay({
        delay: 5000,
        stopOnInteraction: false,
        stopOnMouseEnter: true,
      }),
    ]}
    className='h-full overflow-hidden rounded-md'
  >
    <CarouselContent className='h-full'>
      {slider.data.map((banner, index) => {
        const id = randomId();
        const isFirst = index === 0;

        return (
          <CarouselItem key={id}>
            <PageBanner
              priority={isFirst}
              loading={isFirst ? 'eager' : 'lazy'}
              fetchPriority={isFirst ? 'high' : 'auto'}
              {...banner}
            />
          </CarouselItem>
        );
      })}
    </CarouselContent>

    <CarouselDots className='absolute bottom-0 start-0 m-8' />
  </Carousel>
);

const HomeFirstBanner = () => {
  const { data, isFetched } = useQuery({
    queryKey: [FIRST_PAGE_BANNERS],
    queryFn: () =>
      getPageBanners({
        type: ['LandingPage_Pos1'],
      }),
  });

  const slider = data?.pageSettings?.Landing_Pos1_Slider;
  const banners = [
    data?.pageSettings?.Landing_Pos1_Top_Banner,
    data?.pageSettings?.Landing_Pos1_Bottom_Banner,
  ];

  return (
    <div className='container my-8'>
      <div className='grid h-[13.75rem] grid-cols-10 grid-rows-2 gap-5 md:h-[28.75rem]'>
        <div className='relative col-span-full row-span-2 md:col-span-7'>
          <PageBannerPlaceholder />
          {isFetched && slider ? <HomeSlider slider={slider} /> : null}
        </div>

        <div className='relative col-span-3 col-start-8 max-md:hidden'>
          <PageBannerPlaceholder />
          {isFetched && banners[0] ? <PageBanner {...banners[0]} /> : null}
        </div>
        <div className='relative col-span-3 col-start-8 row-start-2 max-md:hidden'>
          <PageBannerPlaceholder />
          {isFetched && banners[1] ? <PageBanner {...banners[1]} /> : null}
        </div>
      </div>
    </div>
  );
};

const HomeSecondBanner = () => {
  const { ref, isIntersecting } = useIntersectionObserver({
    freezeOnceVisible: true,
  });
  const { data, isFetched } = useQuery({
    queryKey: [SECOND_PAGE_BANNERS],
    queryFn: () =>
      getPageBanners({
        type: ['LandingPage_Pos2'],
      }),
    enabled: isIntersecting,
  });

  const banners = [
    data?.pageSettings?.Landing_Pos2_TopRight_Banner,
    data?.pageSettings?.Landing_Pos2_BottomRight_Banner,
    data?.pageSettings?.Landing_Pos2_Mid_Banner,
    data?.pageSettings?.Landing_Pos2_TopLeft_Banner,
    data?.pageSettings?.Landing_Pos2_BottomLeft_Banner,
  ];

  return (
    <div ref={ref} className='container my-8'>
      <div className='grid h-[30.5rem] grid-cols-10 grid-rows-10 gap-5 md:h-[32.65rem]'>
        <div className='relative col-span-5 row-span-3 md:col-span-3 md:row-span-5'>
          <PageBannerPlaceholder />
          {isFetched && banners[0] ? <PageBanner {...banners[0]} /> : null}
        </div>

        <div className='relative col-span-5 row-span-3 row-start-8 md:col-span-3 md:col-start-1 md:row-span-5 md:row-start-6'>
          <PageBannerPlaceholder />
          {isFetched && banners[1] ? <PageBanner {...banners[1]} /> : null}
        </div>

        <div className='relative col-span-full row-span-4 row-start-4 md:col-span-4 md:col-start-4 md:row-span-full md:row-start-1'>
          <PageBannerPlaceholder />
          {isFetched && banners[2] ? <PageBanner {...banners[2]} /> : null}
        </div>

        <div className='relative col-span-5 row-span-3 md:col-span-3 md:col-start-8 md:row-span-5 md:row-start-1'>
          <PageBannerPlaceholder />
          {isFetched && banners[3] ? <PageBanner {...banners[3]} /> : null}
        </div>

        <div className='relative col-span-5 row-span-3 row-start-8 md:col-span-3 md:col-start-8 md:row-span-5 md:row-start-6'>
          <PageBannerPlaceholder />
          {isFetched && banners[4] ? <PageBanner {...banners[4]} /> : null}
        </div>
      </div>
    </div>
  );
};

const HomeThirdBanner = () => {
  const { ref, isIntersecting } = useIntersectionObserver({
    freezeOnceVisible: true,
  });
  const { data, isFetched } = useQuery({
    queryKey: [THIRD_PAGE_BANNERS],
    queryFn: () =>
      getPageBanners({
        type: ['LandingPage_Pos4'],
      }),
    enabled: isIntersecting,
  });

  const banners = [
    data?.pageSettings?.Landing_Pos4_Right_Banner,
    data?.pageSettings?.Landing_Pos4_Left_Banner,
  ];

  return (
    <div ref={ref} className='container my-8'>
      <div className='grid h-80 grid-cols-10 grid-rows-2 gap-5 md:h-[14.5rem]'>
        {banners.map(banner => {
          const id = randomId();

          return (
            <div
              key={id}
              className='relative col-span-full md:col-span-5 md:row-span-full'
            >
              <PageBannerPlaceholder />
              {isFetched && banner ? <PageBanner {...banner} /> : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HomeFourthBanner = () => {
  const { ref, isIntersecting } = useIntersectionObserver({
    freezeOnceVisible: true,
  });
  const { data, isFetched } = useQuery({
    queryKey: [FOURTH_PAGE_BANNERS],
    queryFn: () =>
      getPageBanners({
        type: ['LandingPage_Pos6'],
      }),
    enabled: isIntersecting,
  });

  const banners = [
    data?.pageSettings?.Landing_Pos6_TopRight_Banner,
    data?.pageSettings?.Landing_Pos6_TopLeft_Banner,
    data?.pageSettings?.Landing_Pos6_BottomRight_Banner,
    data?.pageSettings?.Landing_Pos6_BottomLeft_Banner,
  ];

  return (
    <div ref={ref} className='container my-8'>
      <div className='grid h-[46.5rem] grid-cols-10 grid-rows-4 gap-5 md:h-[32.25rem]'>
        {banners.map(banner => {
          const id = randomId();

          return (
            <div
              key={id}
              className='relative col-span-full md:col-span-4 md:row-span-2 md:first:col-span-6 md:last:col-span-6'
            >
              <PageBannerPlaceholder />
              {isFetched && banner ? <PageBanner {...banner} /> : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export {
  HomeFirstBanner,
  HomeFourthBanner,
  HomeSecondBanner,
  HomeSlider,
  HomeThirdBanner,
};
