'use client';

import { useLocalStorage } from 'usehooks-ts';

import { ProductView, TypographyH5 } from '@/components';
import type { ProductSummary } from '@/types';
import { STORAGE } from '@/utils';

const {
  LOCAL: {
    PRODUCTS: { LAST_VISITED: LOCAL_LAST_VISITED_PRODUCTS },
  },
} = STORAGE;

const HomeLastVisitedProducts = () => {
  const [lastVisitedProducts] = useLocalStorage<ProductSummary[]>(
    LOCAL_LAST_VISITED_PRODUCTS,
    [],
  );

  const isExistedProducts = lastVisitedProducts.length > 0;

  if (isExistedProducts) {
    return (
      <div className='my-8 lg:my-16'>
        <TypographyH5 className='container mb-5'>
          آخرین محصولات بازدید شده شما
        </TypographyH5>
        <ProductView products={lastVisitedProducts.slice(0, 10)} />
      </div>
    );
  }
};

export { HomeLastVisitedProducts };
